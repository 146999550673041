import React from "react"
import { Link } from "gatsby"
import { Media, Row, Col } from "react-bootstrap"
// import SubscribeForm from "./subscribe"
import ArrowWhite from "./arrowWhite"
// import { FormEventAnalytics } from "../utils/analytics"

const Footer = () => (
  <footer className="pt-5" id="footer-main">
    <article className="container">
      <Row className="mb-5">
        <Col lg={2} md={3} className="d-none d-md-block">
          <ul className="footer__menu">
            <li>
              <div className="h5 text-lightgreen">COMPANY</div>
            </li>
            <li>
              <Link to="/" className="link">
                Home
              </Link>
            </li>
            <li>
              <Link to="/implementation/" className="link" activeClassName="active">
                Implementation
              </Link>
            </li>
            <li>
              <a href="https://insights.fuseclassroom.com/" className="link">
                Insights
              </a>
            </li>
            <li>
              <Link to="/about/" className="link" activeClassName="active">
                About
              </Link>
            </li>
            <li>
              <Link to="/press/" className="link" activeClassName="active">
                Press
              </Link>
            </li>
          </ul>
        </Col>

        <Col md={"3"} className="d-none d-md-block">
          <ul className="footer__menu">
            <li>
              <div className="h5 text-lightgreen">PLATFORM</div>
            </li>
            <li>
              <Link to="/platform/learning-management-system" className="link">
                Learning Management System
              </Link>
            </li>
            <li>
              <Link to="/platform/online-live" className="link">
                Online Live
              </Link>
            </li>
            <li>
              <Link to="/platform/student-information-system" className="link">
                Student Information System
              </Link>
            </li>
            <li>
              <Link to="/platform/online-exams" className="link">
                Online Exams
              </Link>
            </li>
            <li>
              <Link to="/platform/community" className="link">
                Community
              </Link>
            </li>
            <li>
              <Link to="/platform/school-management" className="link">
                School Management
              </Link>
            </li>
          </ul>
        </Col>

        <Col md="3" className="d-none d-md-block">
          <ul className="footer__menu">
            <li>
              <div className="h5 text-lightgreen">SOLUTIONS</div>
            </li>
            <li>
              <Link to="/solutions/primary-school" className="link">
                Primary School
              </Link>
            </li>
            <li>
              <Link to="/solutions/middle-school" className="link">
                Middle School
              </Link>
            </li>
            <li>
              <Link to="/solutions/high-school" className="link">
                High School
              </Link>
            </li>
            <li>
              <Link to="/solutions/college-and-university" className="link">
                College and University
              </Link>
            </li>
            <li>
              <Link to="/solutions/test-center" className="link">
                Test Center
              </Link>
            </li>
            <li>
              <Link to="/solutions/training-institute" className="link">
                Training Institute
              </Link>
            </li>
          </ul>
        </Col>

        <Col lg={4}>
          <div className="h5 mb-4 text-lightgreen">CONTACT</div>
          <Media>
            <div className="mr-2">
              <img src="/images/usa.svg" className="img-fluid" alt="Statue" width="37" height="auto" />
            </div>
            <Media.Body>
              <div className="font-weight-bold">USA</div>
              <div>229 West 36th Street,</div>
              <div>New York, NY 10018, United States</div>
              <div className="text-white">+1-800-506-3873</div>
            </Media.Body>
          </Media>

          <Media className="mt-4">
            <div className=" mr-2">
              <img src="/images/nepal.svg" className="img-fluid" alt="Stupa" width="37" height="auto" />
            </div>
            <Media.Body>
              <div className="font-weight-bold">Nepal</div>
              <div>Devkota Sadak, Baneshwor, Kathmandu</div>
              <div className="text-white">+977-01-4440591</div>
            </Media.Body>
          </Media>
        </Col>
        {/* for contact information */}
        <Col lg={5} className="d-none">
          <div>
            <div className="m-0 text-white font-weight-bold">Interested in more information?</div>
            <span className="h3 font-weight-normal">
              {"Let's"} talk<span className="text-lightgreen">.</span>
            </span>
            <div className="my-4 mw-405">
              <Link
                to="/request-for-more-info/"
                className="text-white h5"
                // onClick={() => FormEventAnalytics("Footer_Request_Demo")}
                state={{ page: "website_footer" }}
              >
                Tell us about yourself <ArrowWhite />
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      {/*  */}

      <Row>
        <Col sm={12} className="my-2 mb-4">
          <div className="border-top border-success"></div>
        </Col>
        <Col md={6} className="mb-3">
          <svg
            id="fc-logo"
            xmlns="http://www.w3.org/2000/svg"
            width="156.76"
            height="20.92"
            viewBox="0 0 104.76 13.924"
          >
            <rect
              id="Rectangle_15"
              data-name="Rectangle 15"
              width="0.86"
              height="13.924"
              transform="translate(34.704)"
              fill="#ffffff"
            />
            <g id="Group_36" data-name="Group 36" transform="translate(39.612 0.322)">
              <path
                id="Path_213"
                data-name="Path 213"
                d="M3345.989,4199.808a3.617,3.617,0,0,1,2.678,1.055l-.389.506a3.255,3.255,0,0,0-2.289-.951,3.29,3.29,0,0,0,0,6.578,3.306,3.306,0,0,0,2.5-1.073l.329.521a3.821,3.821,0,0,1-2.826,1.163,3.75,3.75,0,0,1-3.861-3.882A3.793,3.793,0,0,1,3345.989,4199.808Z"
                transform="translate(-3342.133 -4197.007)"
                fill="#ffffff"
              />
              <path
                id="Path_214"
                data-name="Path 214"
                d="M3372.051,4189.766h.628l.005,8.721c0,1.042.539,1.161.884,1.16a1.759,1.759,0,0,0,.224-.015v.58a1.443,1.443,0,0,1-1.736-1.531Z"
                transform="translate(-3363.666 -4189.765)"
                fill="#ffffff"
              />
              <path
                id="Path_215"
                data-name="Path 215"
                d="M3385.989,4202.865h.494v-.253c0-1.652-.854-2.217-2.05-2.216a3.526,3.526,0,0,0-2.138.716l-.359-.506a3.927,3.927,0,0,1,2.527-.82c1.72,0,2.648.951,2.649,2.812l0,4.807h-.628v-.981a3.4,3.4,0,0,1,.06-.67h-.03a2.872,2.872,0,0,1-2.616,1.832,2.292,2.292,0,0,1-2.544-2.156C3381.353,4202.9,3384.658,4202.866,3385.989,4202.865Zm-2.062,4.139c1.63,0,2.557-1.684,2.556-3.157v-.4H3386c-1.271,0-3.993,0-3.992,1.938A1.722,1.722,0,0,0,3383.927,4207Z"
                transform="translate(-3370.361 -4196.99)"
                fill="#ffffff"
              />
              <path
                id="Path_216"
                data-name="Path 216"
                d="M3407.958,4206.021a3.26,3.26,0,0,0,2.318.936c.913,0,1.72-.477,1.719-1.4,0-1.89-4.114-1.352-4.115-3.778,0-1.324,1.12-2.009,2.391-2.01a2.957,2.957,0,0,1,2.214.8l-.374.506a2.565,2.565,0,0,0-1.885-.7c-.853,0-1.689.388-1.689,1.355,0,1.935,4.114,1.352,4.116,3.822a2.142,2.142,0,0,1-2.392,2.011,3.625,3.625,0,0,1-2.708-1.07Z"
                transform="translate(-3389.218 -4196.979)"
                fill="#ffffff"
              />
              <path
                id="Path_217"
                data-name="Path 217"
                d="M3430.155,4206.007a3.265,3.265,0,0,0,2.319.936c.913,0,1.72-.477,1.719-1.4,0-1.89-4.114-1.352-4.115-3.777,0-1.324,1.12-2.01,2.391-2.011a2.957,2.957,0,0,1,2.214.8l-.374.506a2.569,2.569,0,0,0-1.885-.7c-.853,0-1.69.389-1.689,1.356,0,1.935,4.113,1.351,4.115,3.822a2.141,2.141,0,0,1-2.392,2.01,3.624,3.624,0,0,1-2.708-1.069Z"
                transform="translate(-3405.194 -4196.969)"
                fill="#ffffff"
              />
              <path
                id="Path_218"
                data-name="Path 218"
                d="M3454.227,4200.228h.628v1.414a3.147,3.147,0,0,1-.059.61h.03a2.665,2.665,0,0,1,2.391-2.085,2.322,2.322,0,0,1,.434.044v.625a2.216,2.216,0,0,0-2.571,2.055,5.28,5.28,0,0,0-.223,1.563l0,3.214h-.628Z"
                transform="translate(-3422.808 -4197.265)"
                fill="#ffffff"
              />
              <path
                id="Path_219"
                data-name="Path 219"
                d="M3472.461,4199.727a3.837,3.837,0,0,1,3.876,3.853,3.874,3.874,0,1,1-7.747,0A3.836,3.836,0,0,1,3472.461,4199.727Zm0,7.188a3.243,3.243,0,0,0,3.213-3.335,3.216,3.216,0,1,0-6.431,0A3.243,3.243,0,0,0,3472.466,4206.915Z"
                transform="translate(-3433.146 -4196.949)"
                fill="#ffffff"
              />
              <path
                id="Path_220"
                data-name="Path 220"
                d="M3504.475,4199.707a3.837,3.837,0,0,1,3.876,3.853,3.874,3.874,0,1,1-7.747,0A3.837,3.837,0,0,1,3504.475,4199.707Zm0,7.188a3.242,3.242,0,0,0,3.213-3.335,3.215,3.215,0,1,0-6.431,0A3.243,3.243,0,0,0,3504.48,4206.9Z"
                transform="translate(-3456.187 -4196.934)"
                fill="#ffffff"
              />
              <path
                id="Path_221"
                data-name="Path 221"
                d="M3534.585,4199.859h.628v1.31a3.072,3.072,0,0,1-.06.61h.03a3.337,3.337,0,0,1,2.871-2.1,2.073,2.073,0,0,1,2.364,2.022h.03a3.253,3.253,0,0,1,2.84-2.026c1.929,0,2.5,1.129,2.5,2.9l0,4.718h-.628l0-4.495c0-1.4-.285-2.545-1.871-2.544a3,3,0,0,0-2.631,2.264,3.9,3.9,0,0,0-.149,1.146l0,3.631h-.628l0-4.495c0-1.28-.181-2.545-1.826-2.544a3.071,3.071,0,0,0-2.721,2.4,3.683,3.683,0,0,0-.119,1.012l0,3.631h-.628Z"
                transform="translate(-3480.643 -4196.911)"
                fill="#ffffff"
              />
            </g>
            <g id="Group_37" data-name="Group 37" transform="translate(0 0.067)">
              <path
                id="Path_222"
                data-name="Path 222"
                d="M3201.752,4193.9h-.942v-2.01h.943v-.179c0-2.515,2.287-2.859,3.528-2.86a6.185,6.185,0,0,1,.777.044l0,2.173a2.806,2.806,0,0,0-.419-.029c-.509,0-1.3.09-1.3.745v.1h1.421v2.009h-1.42l0,5.655-2.587,0Z"
                transform="translate(-3200.808 -4188.852)"
                fill="#ffffff"
              />
              <path
                id="Path_223"
                data-name="Path 223"
                d="M3222.2,4202.206a.3.3,0,0,0-.329-.327h-.568v-2.157l2.393,0c.762,0,1.092.327,1.092,1.086l0,3.408c0,.714.285,1.011.823,1.011,1.107,0,1.63-.968,1.629-2.114l0-3.393,2.588,0,0,5.179a.3.3,0,0,0,.33.327h.568v2.158h-2.288c-.7,0-1.092-.341-1.092-.862v-.1a1.467,1.467,0,0,1,.015-.178h-.03a2.647,2.647,0,0,1-2.392,1.325c-1.525,0-2.737-.712-2.739-2.87Z"
                transform="translate(-3215.556 -4196.686)"
                fill="#ffffff"
              />
              <path
                id="Path_224"
                data-name="Path 224"
                d="M3258.533,4204.036a3.772,3.772,0,0,0,2.259,1.1c.374,0,.583-.1.583-.372,0-.641-3.86-.847-3.861-3.332,0-1.651,1.539-2.367,3.169-2.368,1.256,0,2.992.355,2.993,1.739v.908h-2.243v-.372c0-.208-.33-.356-.7-.356s-.688.12-.688.357c0,.789,3.9.682,3.906,3.257,0,1.547-1.36,2.486-3.154,2.487a4.672,4.672,0,0,1-3.411-1.4Z"
                transform="translate(-3241.525 -4196.216)"
                fill="#ffffff"
              />
              <path
                id="Path_225"
                data-name="Path 225"
                d="M3288.115,4199.046a3.41,3.41,0,0,1,3.562,3.7c0,.253-.059.773-.059.773l-4.86,0a1.716,1.716,0,0,0,1.795,1.4,3.6,3.6,0,0,0,2.049-.8l1.033,1.725a4.977,4.977,0,0,1-3.29,1.223,3.985,3.985,0,0,1-4.235-4.015A3.825,3.825,0,0,1,3288.115,4199.046Zm.914,2.916a.968.968,0,0,0-.913-.981,1.235,1.235,0,0,0-1.286.983Z"
                transform="translate(-3260.761 -4196.203)"
                fill="#ffffff"
              />
            </g>
          </svg>
        </Col>
        <Col md={6} className="text-md-right mb-3">
          <span className="">
            © 2021{" "}
            <a href="https://fusemachines.com/" target="_blank" className="text-white text-decoration-none">
              Fusemachines Inc.
            </a>
          </span>
          <span className="mx-2">|</span>
          <Link to="/privacy-policy" className="text-white text-decoration-none">
            Privacy Policy
          </Link>
          <span className="mx-2">|</span>
          <Link to="/eula" className="text-white text-decoration-none">
            EULA
          </Link>
        </Col>
        {/* <Col md={5} className="text-md-right">
          Privacy Policy | EULA
        </Col> */}
      </Row>
    </article>
  </footer>
)

export default Footer
