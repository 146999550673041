import React from "react"
import { Link } from "gatsby"

export default class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      toggleNavbar: false,
      dropdownToggle: false,
    }
  }

  navbarToggle = () => {
    this.setState({ toggleNavbar: !this.state.toggleNavbar })
  }

  dropdownClick = e => {
    e.preventDefault()

    this.setState({ dropdownToggle: !this.state.dropdownToggle })
    document.addEventListener("click", this.hideDropdownMenu)
  }

  hideDropdownMenu = () => {
    this.setState({ dropdownToggle: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu)
    })
  }

  render() {
    const { dropdownToggle } = this.state
    const { itemName, dropdownElement, mainUrl } = this.props
    let navitem = this.props.dropdownElement

    return (
      <div className="dropdown">
        {this.props.mainUrl ? (
          <div onClick={this.dropdownClick} className="dropdown-toggle nav-link">
            <Link activeClassName="active" to={this.props.mainUrl}>
              {this.props.itemName}
            </Link>
          </div>
        ) : (
          <div onClick={this.dropdownClick} className="nav-link dropdown-toggle">
            {this.props.itemName}
          </div>
        )}

        <div className={`dropdown-menu ${dropdownToggle ? "show" : ""}`}>
          {navitem.map(lists => (
            <Link activeClassName="active" key={lists.id} to={lists.url}>
              {lists.subNav}
            </Link>
          ))}
        </div>
      </div>
    )
  }
}
