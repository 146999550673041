import axios from "axios"
import { showNotification } from "./notification"
import config from "../config"

// Add a response interceptor
axios.interceptors.response.use(
  response => {
    // Do something with response data
    return response
  },
  error => {
    if (typeof error === "undefined") {
      // when backend server is not available at all
      showNotification("We cannot seem to reach our servers. Please check your internet connection and try again", true)
      return Promise.reject(error)
    } else if (error.code === "ECONNABORTED") {
      showNotification("Request Time Out. Please try again", true)
    } else if (typeof error.response === "undefined") {
      // when request is timeout
      showNotification("Request Timeout", true)
      return Promise.reject(error.response)
    } else if (error.response.status === 401) {
      showNotification("Unauthorized", true)
      /* if(location.pathname === '/redirect'){
          return Promise.reject(error.response);
        }
        location.assign("/logout"); */
      return Promise.reject(error.response)
    } else if (error.response.status === 403) {
      showNotification("Unauthorized", true)
      return Promise.reject(error.response)
    } else if (error.response.status === 500) {
      showNotification("Our system is currently facing a problem. Please check back later", true)

      return Promise.reject(error.response)
    } else {
      // we don't know what's going on
      return Promise.reject(error.response)
    }
  }
)

const mainAxios = axios.create({
  baseURL: config.apiUrl,
})

const fuseClassroomAxios = axios.create({
  baseURL: config.fuseClassroomApiUrl,
})

export { mainAxios, fuseClassroomAxios }
