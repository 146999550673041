import React from "react"

const ArrowWhite = () => (
  <div className="ml-2 d-inline-block">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.268"
      height="8.174"
      className="align-middle"
      viewBox="0 0 17.268 8.174"
    >
      <g id="Group_11692" data-name="Group 11692" transform="translate(-3115.5 -185.173)">
        <line
          id="Line_207"
          data-name="Line 207"
          x2="13.303"
          transform="translate(3115.5 189.586)"
          fill="#fff"
          stroke="#ffffff"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
        <path
          id="Path_24005"
          data-name="Path 24005"
          d="M3132.5,189.26l-3.5-3v6Z"
          transform="translate(-0.5)"
          fill="#ffffff"
          stroke="#ffffff"
          strokeMiterlimit="10"
          strokeWidth="1"
        />
      </g>
    </svg>
  </div>
)

export default ArrowWhite
