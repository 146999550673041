import { mainAxios, fuseClassroomAxios } from "../utils/axios"
import config from "../config"

// API URL request a demo
export const requestDemo = data => {
  return new Promise((resolve, reject) => {
    mainAxios
      .request({
        method: "POST",
        url: "/requestdemo",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: JSON.stringify({
          fullName: data.fullName,
          companyName: data.companyName,
          jobTitle: data.jobTitle,
          workEmail: data.workEmail,
          phoneNumber: data.phoneNumber,
          companyWebsite: data.companyWebsite,
          source: data.source,
          medium: data.medium,
          content: data.content,
          campaign: data.campaign,
          page_ref: data.page_ref,
        }),
        noRetry: true,
      })
      .then(response => {
        localStorage.clear()
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// API URL subscribe
export const subscribeUs = data => {
  return new Promise((resolve, reject) => {
    mainAxios
      .request({
        method: "POST",
        url: "/subscription",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: JSON.stringify({
          email: data.email,
        }),
        noRetry: true,
      })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

// API URL request a demo get

// API URL get GeoLocation
export const getGeoLocation = async () => {
  return new Promise((resolve, reject) => {
    mainAxios
      .request({
        method: "GET",
        url: "https://jsonip.com",
      })
      .then(res => {
        return new Promise((resolve, reject) => {
          mainAxios
            .request({
              method: "GET",
              url: "https://cors-anywhere.herokuapp.com/http://www.geoplugin.net/json.gp?ip=" + res.data.ip,
            })
            .then(response => {
              return response.data
            })
            .catch(err => {
              reject(err)
            })
        })
      })
      .catch(err => {
        reject(err)
      })
  })
}

export const getReleaseNotes = async () => {
  return fuseClassroomAxios.request({
    method: "GET",
    url: `api/v2/releaseNote/fetchAllReleaseNotes?status=PUBLISHED&audience=&page=1&size=100`,
  })
}
