import Noty from "noty"

/**
 * Create a new alert with given type and message
 *
 * @param {string} type
 * @param {string} text
 * @param {Object} options
 * @param {boolean} error
 * @param {string} message
 */
export const showNotification = (message, error) => {
  return new Noty({
    type: error ? "error" : "success",
    timeout: 5000,
    layout: "topCenter",
    text: message,
    closeWith: ["button"],
  }).show()
}
